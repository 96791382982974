import React, {Fragment, useEffect, useState} from "react";
import Header from "../../Common/Header";
import ThankYou from "../ThankYou/ThankYou";
import {getTotalRefund} from "../../../helpers/common";
import {Spinner} from "react-bootstrap";

const Apply = () => {
  useEffect(
    () => setTotal(getTotalRefund(ref2020, ref2021))
  );
  const [total, setTotal] = useState('$X,XXX,XXX');
  const [ref2020, setRef2020]  = useState(0);
  const [ref2021, setRef2021]  = useState(0);
  const [isSend, setIsSend]  = useState(false);

  return (
    window.location.hash === '#/thank-you' ?
      <ThankYou/>
      :
    <Fragment>
      <Header/>
      <div id="preview-container" className="preview-container hl_page-preview--content section-apply">
        <div className=" container-wrapper">
          <h3 className="mt-2">Please fill out your information. We will contact you within 24 hours of your inquiry. Thank you</h3>
          <div className="mb-4-5">

          </div>
          <form id="survey-form" className="survey-form mt-5" method="post" action="/send.php">
            <div className="row">
              <div id="div_id_first_name" className=" col-sm-6 mb-3">
                <label htmlFor="id_first_name" className="form-label requiredField">
                  First name
                </label>
                <input type="text" name="first_name" className="textinput textInput form-control" id="id_first_name"/>
              </div>
              <div id="div_id_last_name" className=" col-sm-6 mb-3">
                <label htmlFor="id_last_name" className="form-label requiredField">
                  Last name
                </label>
                <input type="text" name="last_name" className="textinput textInput form-control" id="id_last_name"/>
              </div>
              <div id="div_id_email" className=" col-sm-6 mb-3">
                <label htmlFor="id_email" className="form-label requiredField">
                  Email
                </label>
                <input type="email"
                       name="email"
                       placeholder="e.g. example@example.com"
                       className="emailinput form-control" id="id_email"/>
              </div>
              <div id="div_id_phone_number" className=" col-sm-6 mb-3">
                <label htmlFor="id_phone_number" className="form-label requiredField">
                  Phone number
                </label>
                <input type="tel"
                       name="phone_number"
                       placeholder="(555) 555-5555"
                       className="textinput textInput form-control"
                       id="id_phone_number"/>
              </div>
              <div id="div_id_average_fulltime_employees_2020" className="col-sm-12 mb-3">
                <label htmlFor="id_average_fulltime_employees_2020" className="form-label requiredField">
                  2020 Part Time / Full Time Employees
                </label>
                <input type="number" name="average_fulltime_employees_2020"
                       min="0"
                       placeholder="ex: 23"
                       className="numberinput form-control" id="id_average_fulltime_employees_2020"
                       onChange={(e) => setRef2020(parseInt(e.target.value))} />
              </div>
              <div id="div_id_average_fulltime_employees_2021" className="col-sm-12 mb-3"><label
                htmlFor="id_average_fulltime_employees_2021" className="form-label requiredField">
                2021 Part Time / Full Time Employees </label>
                <input type="number"
                       name="average_fulltime_employees_2021"
                       min="0" placeholder="ex: 23"
                       className="numberinput form-control"
                       id="id_average_fulltime_employees_2021"
                       onChange={(e) => setRef2021(parseInt(e.target.value))}/>
              </div>
              <div
                //style={{"font-size":"1.375rem", "line-height": 1}}
                className="mt-4 d-flex justify-items-between align-items-end">
                <div className="mr-2 form-label">Potential ERC amount*: </div>
                <div className="big-money form-label"
                  //style={{"margin-left": "auto"}}
                     id="total-estimate">
                  { total }
                </div>
              </div>
              <div className="small text-muted mt-2">
                *This is an estimate only based on the employee counts entered and does not represent a guarantee of the
                credit amount you will receive, or that you will qualify for the program.
              </div>
            </div>
            <div className="row">
              <button type="submit" className="cbutton survey-submit"
                      onClick={() => setIsSend(true)}
              >
                { isSend
                  ?  <Spinner style={{marginBottom:27}} animation="border" variant="danger" />
                  : "Let's get started"
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}
export default Apply