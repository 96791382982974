const SectionWhy = () => {
  return (
    <div className="fullSection none noBorder radius0 none c-section c-wrapper section-why section-Or-d-DesIL"
         id="section-Or-d-DesIL" data-v-0bad29cf="">

      <div className="inner" data-v-0bad29cf="">

        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-kV2k02LTwkx"
             id="row-kV2k02LTwkx" data-v-0bad29cf="">

          <div className="inner" data-v-0bad29cf="">

            <div id="col-KFOn6xaXobz" className="c-column c-wrapper col-KFOn6xaXobz" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-HSjnnYeZB5y" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-HSjnnYeZB5y text-output cheading-HSjnnYeZB5y none noBorder radius0 none">
                    <div><h1>Why ERC Refunders?</h1></div>
                  </div>

                </div>

                <div id="divider-oHPQEJzcsch" className="c-divider c-wrapper divider" data-v-0bad29cf="">

                  <div id="divider-oHPQEJzcsch" className="divider-element cdivider"></div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-TV75mIoGZEb"
             id="row-TV75mIoGZEb" data-v-0bad29cf="">

          <div className="inner" data-v-0bad29cf="">

            <div id="col-UjL6J2mcbkn" className="c-column c-wrapper col-UjL6J2mcbkn" data-v-0bad29cf="">

              <div className="none noBorder radius10 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-JNkOquowbT1" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-JNkOquowbT1 text-output cheading none noBorder radius0 none">
                    <div>
                      <i className="fas fa-user-tie"/>
                    </div>
                  </div>

                </div>

                <div id="sub-heading-ttaE5uGiOf7" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading text-output csub-heading-ttaE5uGiOf7 none noBorder radius0 none">
                    <div><h2>Dedicated to ERC</h2></div>
                  </div>

                </div>

                <div id="paragraph-H3Gx9b4rJEN" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="cparagraph-mon paragraph-H3Gx9b4rJEN text-output cparagraph-H3Gx9b4rJEN none noBorder radius0 none">
                    <div><p>No need to be the guinea pig for your CPA. We average 10-20%
                      more funding than a CPA not familiar with the program.</p></div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-u6tas4xDnOY" className="c-column c-wrapper col-u6tas4xDnOY" data-v-0bad29cf="">

              <div className="none noBorder radius10 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-wglfnwsxQpZ" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-wglfnwsxQpZ text-output cheading none noBorder radius0 none">
                    <div>
                      <i className="fas fa-search-dollar"/>
                    </div>
                  </div>

                </div>

                <div id="sub-heading-GZlvK2zgAQ_" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading text-output csub-heading-GZlvK2zgAQ_ none noBorder radius0 none">
                    <div><h2>Maximum Funding</h2></div>
                  </div>

                </div>

                <div id="paragraph-k8X3To-iiMh" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-k8X3To-iiMh text-output cparagraph-k8X3To-iiMh none noBorder radius0 none">
                    <div><p>We evaluate your claim in every way possible to ensure we
                      maximize your credit.</p></div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-brOoWExOccv" className="c-column c-wrapper col-brOoWExOccv" data-v-0bad29cf="">

              <div className="none borderFull radius10 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-Vuu53CapYc3" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-Vuu53CapYc3 text-output cheading none noBorder radius0 none">
                    <div>
                      <i className="fas fa-bolt"/>
                    </div>
                  </div>

                </div>

                <div id="sub-heading-ZS0l-Fvdplq" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading text-output csub-heading-ZS0l-Fvdplq none noBorder radius0 none">
                    <div><h2>Lightning Fast Results</h2></div>
                  </div>

                </div>

                <div id="paragraph-0KseBXy7RH4" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-0KseBXy7RH4 text-output cparagraph-0KseBXy7RH4 none noBorder radius0 none">
                    <div><p>Our streamlined processes allow for faster results, which
                      means faster funding.</p></div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-Iqiks8UYr8" id="row-Iqiks8UYr8"
             data-v-0bad29cf="">

          <div className="inner" data-v-0bad29cf="">

            <div id="col-U3UtveYJql" className="c-column c-wrapper col-U3UtveYJql desktop-only" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div className="empty-slot" data-v-0bad29cf="" data-v-2f491720="">
                  <div className="empty-component-wrapper" data-v-2f491720="">
                    <div className="empty-component-min-height" data-v-2f491720=""/>
                  </div>
                </div>

              </div>

            </div>

            <div id="col-CE8TixrI0K" className="c-column c-wrapper col-CE8TixrI0K" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-C_y53gQX4oE" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-C_y53gQX4oE text-output cheading none noBorder radius0 none">
                    <div>
                      <i className="fas fa-users"/>
                    </div>
                  </div>

                </div>

                <div id="sub-heading-fdVHKsOn1-7" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading text-output csub-heading-fdVHKsOn1-7 none noBorder radius0 none">
                    <div><h2>ERC Program Refunders</h2></div>
                  </div>

                </div>

                <div id="paragraph-2IrSCtDLW95" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-2IrSCtDLW95 text-output cparagraph-2IrSCtDLW95 none noBorder radius0 none">
                    <div><p>Our team strictly focuses on ERC allowing us to be the
                      experts and resulting in more funding for your business.</p>
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-ExZa4gnGg8" className="c-column c-wrapper col-ExZa4gnGg8 desktop-only" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div className="empty-slot" data-v-0bad29cf="" data-v-2f491720="">
                  <div className="empty-component-wrapper" data-v-2f491720="">
                    <div className="empty-component-min-height" data-v-2f491720=""></div>
                  </div>
                </div>

              </div>

            </div>

            <div id="col-QTFc8m6JZH" className="c-column c-wrapper col-QTFc8m6JZH" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="heading-nNvwDNEh2GS" className="c-heading c-wrapper" data-v-0bad29cf="">

                  <div className="heading-nNvwDNEh2GS text-output cheading none noBorder radius0 none">
                    <div>
                      <i className="fas fa-handshake"/>
                    </div>
                  </div>

                </div>

                <div id="sub-heading-9iC3Hjj98tD" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading text-output csub-heading-9iC3Hjj98tD none noBorder radius0 none">
                    <div><h2>Professional Support</h2></div>
                  </div>

                </div>

                <div id="paragraph-djfBxbY0mqu" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-djfBxbY0mqu text-output cparagraph-djfBxbY0mqu none noBorder radius0 none">
                    <div><p>Although our process is quick and painless, when you have
                      questions we have answers with a dedicated team of ERC support
                      Refunders</p></div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-XkpSlc8-Tg" className="c-column c-wrapper col-XkpSlc8-Tg desktop-only" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div className="empty-slot" data-v-0bad29cf="" data-v-2f491720="">
                  <div className="empty-component-wrapper" data-v-2f491720="">
                    <div className="empty-component-min-height" data-v-2f491720=""/>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

);
}

export default SectionWhy;