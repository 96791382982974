const SectionReadyToStart = () => {
  return (
    <div className="fullSection section-ready none noBorder radius0 none c-section c-wrapper section-0XqfBccLwr" 
         id="section-0XqfBccLwr" data-v-0bad29cf="">
      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-xuHp2AzyXnZ"
             id="row-xuHp2AzyXnZ" data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-U6Rze568BdG" className="c-column c-wrapper col-U6Rze568BdG" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="heading-8_OpugX7652" className="c-heading c-wrapper" data-v-0bad29cf="">
                  <div className="heading-8 heading-8_OpugX7652 text-output cheading-8 cheading-8_OpugX7652 none noBorder radius0 none">
                    <div>
                      <h1 className={"cheading-8"}><strong>Ready to Get Started?</strong></h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="col-QzbSWIbBB6P" className="c-column c-wrapper col-QzbSWIbBB6P" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="button-qasDYYvRuaW" className="c-button c-wrapper button-qasDYYvRuaW" data-v-0bad29cf="">
                  <a href="/#/apply"
                     className="button cbutton cbutton-qasDYYvRuaW none btnshadow custom btn-vp btn-hp borderFull radius100 none"
                     data-v-3e12c334="">
                    <span className="main-heading-group" data-v-3e12c334="">
                        <span className="button-icon-start" data-v-3e12c334=""/>
                        <span className="main-heading-button" data-v-3e12c334="">Begin Qualifying</span>
                        <span className="button-icon-end" data-v-3e12c334=""/>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionReadyToStart;