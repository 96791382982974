import React from "react";
import {HashLink} from "react-router-hash-link";
import {scrollWithOffset} from "../../helpers/common";

const Footer = () => {

  return (
    <div className="fullSection none borderTop radius0 none c-section c-wrapper section-footer section-rhz1jdmeN"
         id="section-rhz1jdmeN" data-v-0bad29cf="">

      <div className="inner" data-v-0bad29cf="">

        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-TBgNgat18m" id="row-TBgNgat18m"
             data-v-0bad29cf="">

          <div className="inner" data-v-0bad29cf="">

            <div id="col-5q_NKDfSw" className="c-column c-wrapper col-5q_NKDfSw" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="image-3U-0ORNZT" className="c-image c-wrapper image-3U-0ORNZT" data-v-0bad29cf="">

                  <div className="image-container cimage-3U-0ORNZT" data-v-5d0761c8="">
                    <div data-v-5d0761c8="">
                      <div>
                        <img  src="./images/ERC-logo512.jpg" alt=""
                             className="img-none img-border-none img-shadow-none img-effects-none" height="auto"
                             width="100" loading="lazy"/>
                      </div>
                    </div>
                  </div>

                </div>

                <div id="paragraph-8y9fsv6st" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-8y9fsv6st text-output cparagraph-8y9fsv6st none noBorder radius0 none">
                    <div>
                      <p>
                        © 2022 | <HashLink smooth to="/privacy-policy" target="_self">Privacy Policy</HashLink>
                      </p>
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-sRmpfjA-6r" className="c-column c-wrapper col-sRmpfjA-6r" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="sub-heading-UmzXC9-Yt" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div className="sub-heading-UmzXC9-Yt text-output csub-heading-UmzXC9-Yt none noBorder radius0 none">
                    <div><h2>About ERC Refunders</h2></div>
                  </div>

                </div>

                <div id="paragraph-tzNw5_bcf" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-tzNw5_bcf text-output cparagraph-tzNw5_bcf none noBorder radius0 none">
                    <div>
                      <p>
                        ERC Refunders is a specialty tax service company
                        exclusively dedicated to understanding and maximizing the
                        CARES Act Employee Retention Credit (ERC) for small
                        businesses
                        affected negatively by COVID 19.
                      </p>
                    </div>
                  </div>

                </div>

                <div id="paragraph-G9dB7bm0H" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-G9dB7bm0H text-output cparagraph-G9dB7bm0H none noBorder radius0 none">
                    <div><p>Email: <a href="mailto:info@erctaxrefunders.com">info@erctaxrefunders.com</a></p></div>
                    <div><p>Tel: 669-888-0808</p></div>
                  </div>

                </div>

              </div>

            </div>

            <div id="col-0SWz32LKH" className="c-column c-wrapper col-0SWz32LKH" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="sub-heading-kExEWK-jyT" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  <div
                    className="sub-heading-kExEWK-jyT text-output csub-heading-kExEWK-jyT none noBorder radius0 none">
                    <div><h2>Quick Links</h2></div>
                  </div>

                </div>

                <div id="paragraph-wKaBuq3Ue" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-wKaBuq3Ue text-output cparagraph-wKaBuq3Ue none noBorder radius0 none">
                    <div>
                      <p>
                        <HashLink smooth to="/#section-Or-d-DesIL" target="" scroll={el => scrollWithOffset(el)}>Why ERC Refunders?</HashLink>
                      </p>
                    </div>
                  </div>

                </div>

                <div id="paragraph-6lKjxRH-5" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-6lKjxRH-5 text-output cparagraph-6lKjxRH-5 none noBorder radius0 none">
                    <div>
                      <p>
                        <HashLink smooth to="/#section-lJXmKdOUFI" target="" scroll={el => scrollWithOffset(el)}>ERC Qualifications</HashLink>
                      </p>
                    </div>
                  </div>

                </div>

                <div id="paragraph-Wx22WliWj" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-Wx22WliWj text-output cparagraph-Wx22WliWj none noBorder radius0 none">
                    <div>
                      <p>
                        <HashLink smooth to="/#section-X2M8ZlQCsa" target="" scroll={el => scrollWithOffset(el)}>FAQ</HashLink>
                      </p>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-zbNawv0dBa" id="row-zbNawv0dBa"
             data-v-0bad29cf="">

          <div className="inner" data-v-0bad29cf="">

            <div id="col-bEZmJ-PvZ" className="c-column c-wrapper col-bEZmJ-PvZ" data-v-0bad29cf="">

              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">

                <div id="paragraph-flbPQ4xhM" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                  <div className="paragraph-flbPQ4xhM text-output cparagraph-flbPQ4xhM none noBorder radius0 none">
                    <div>
                      <p>
                        <strong>
                          ERC Refunders, LLC DOES NOT provide any legal or
                          accounting advice and users of this web site should
                          consult with their own lawyer and C.P.A. for legal and
                          accounting
                          advice.
                        </strong>
                      </p>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}
export default Footer
