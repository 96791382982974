import {useEffect, useState} from "react";
import {getTotalRefund} from "../../../helpers/common";
import {Link} from "react-router-dom";

const SectionCalculate = () => {

  useEffect(
    () => setTotal(getTotalRefund(ref2020, ref2021))
  );
  const [total, setTotal] = useState('$X,XXX,XXX');
  const [ref2020, setRef2020]  = useState(0);
  const [ref2021, setRef2021]  = useState(0);

  return (
    <main id="main-content" className="body-container-wrapper container-wrapper section-calculate">
      <div className="container-fluid body-container body-container--landing-page body-container--landing-page---slim">
        <div className="row-fluid-wrapper">
          <div className="row-fluid">
            <div className="span12 widget-span widget-type-cell " data-widget-type="cell"
                 data-x="0" data-w="12">

              <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd_area-row-0-max-width-section-centering dnd_area-row-0-vertical-alignment dnd-section">
                <div className="row">
                  <div className="col-md-6 span6 widget-span widget-type-cell cell_16614864046072-vertical-alignment dnd-column"
                       data-widget-type="cell" data-x="0" data-w="6">

                    <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module"
                             data-widget-type="custom_widget" data-x="0"
                             data-w="12">
                          <div id="hs_cos_wrapper_widget_1661486404487"
                               className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text"
                               data-hs-cos-general-type="widget"
                               data-hs-cos-type="module">
                                  <span id="hs_cos_wrapper_widget_1661486404487_"
                                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                        data-hs-cos-general-type="widget"
                                        data-hs-cos-type="rich_text">
                                    <div className="heading-HSjnnYeZB5y text-output cheading-HSjnnYeZB5y none noBorder radius0 none">
                                      <h1 className="">Calculate your<br/> payroll tax refund.</h1>
                                    </div>
                                    <div id="divider-CNWB_n9KQ" className="c-divider c-wrapper divider-CNWB_n9KQ"
                                         data-v-0bad29cf="">
                                      <div id="divider-CNWB_n9KQ" className="divider-element cdivider-CNWB_n9KQ"/>
                                    </div>
                                  <p><span>Billions back to small and medium-sized businesses—use Innovation Refund's expert CPAs to maximize your Employee Retention Credit—we even file your amended 941 Forms.</span></p>
                                  </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 span6 widget-span widget-type-cell cell_1662109401408-vertical-alignment dnd-column"
                       data-widget-type="cell" data-x="6" data-w="6">

                    <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module"
                             data-widget-type="custom_widget" data-x="0"
                             data-w="12">
                          <div id="hs_cos_wrapper_widget_1662105977312"
                               className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                               data-hs-cos-general-type="widget"
                               data-hs-cos-type="module">
                            <div className="calc-group">
                              <div className="">
                                <label className="form-label">2020 Part Time / Full Time Employees</label>
                                <input id="fte20" name="fte21" type="number"
                                       className="form-control"
                                       onChange={(e) => setRef2020(parseInt(e.target.value))} />
                              </div>
                              <div className="">
                                <label className="form-label">2021 Part Time / Full Time Employees</label>
                                <input id="fte21" name="fte21" type="number"
                                       className="form-control"
                                       onChange={(e) => setRef2021(parseInt(e.target.value))}/>
                              </div>
                              <div className="">
                                <label id="lblRefund" className="csub-heading noBorder">
                                  { total }
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module"
                             data-widget-type="custom_widget" data-x="0"
                             data-w="12">
                          <div id="hs_cos_wrapper_widget_1661490954631"
                               className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                               data-hs-cos-general-type="widget"
                               data-hs-cos-type="module">
                            <div className="button-wrapper">
                              <Link className="button cbutton"
                                 to="/apply">
                                Get My Refund
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  );
}

export default SectionCalculate;