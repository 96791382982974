import {Link} from "react-router-dom";

const SectionIntro = () => {
  return (
    <div className="fullSection none noBorder radius0 none c-section c-wrapper section-0rmG2WGM- section-intro"
         id="section-0rmG2WGM-" data-v-0bad29cf="">
      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-OKEPBBGVRy" id="row-OKEPBBGVRy"
             data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-rGx1qa4oTG" className="c-column c-wrapper col-rGx1qa4oTG" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <img src="./images/ERC-logo512.jpg" alt="Brand Logo"
                     className="noBorder radius0 none" height="auto" width="280"/>
                <div className="heading-dvPFLWJBz_3 text-output cheading-dvPFLWJBz_3 none noBorder radius0 none">
                  <div>
                    <h1><span className="highlightedText1">Work directly </span></h1>
                    {/*<h1><span className="highLightedText1"/></h1>*/}
                    <p>With a tax <strong className="highLightedText1">attorney</strong></p>
                  </div>
                </div>

                <div id="sub-heading-K8OhhhPBdrz" className="c-sub-heading c-wrapper" data-v-0bad29cf="">
                  <div
                    className="sub-heading-K8OhhhPBdrz text-output csub-heading-K8OhhhPBdrz none noBorder radius0 none">
                    <div><h2>We specialize in Maximizing ERC Funding for Small Businesses.</h2></div>
                  </div>

                </div>

                <div id="button-UN5MS-plNq" className="c-button c-wrapper button-UN5MS-plNq" data-v-0bad29cf="">
                  <Link to="/apply" rel="noreferrer"
                  className="cbutton-UN5MS-plNq none btnshadow custom btn-vp btn-hp borderFull radius50 none"
                  data-v-02382963="">
                    <span className="main-heading-group" data-v-02382963="">
                    <span className="button-icon-start" data-v-02382963="">
                    </span>
                    <span className="main-heading-button" data-v-02382963="">Find out if you qualify</span>
                    <span className="button-icon-end" data-v-02382963=""/>
                    </span>
                   </Link>
                </div>
              </div>
            </div>
            <div id="col-ND6OU9BySbB" className="c-column c-wrapper col-ND6OU9BySbB" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="image-1beauHrR5Wl" className="c-image c-wrapper image-1beauHrR5Wl" data-v-0bad29cf="">
                  <div className="image-container cimage-1beauHrR5Wl" data-v-5d0761c8="">
                    <div data-v-5d0761c8="">
                      <div>
                        <img className="img-round-corners img-border-none shadow4 img-effects-none img-none img-border-none img-shadow-none img-effects-none"
                          src="./images/attorney-transformed.jpeg"
                          alt="" height="auto"
                          width="auto" loading="lazy"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default SectionIntro;