const SectionQualifications = () => {
  return (
    <div className="section-qualifications fullSection none noBorder radius0 none c-section c-wrapper section-lJXmKdOUFI"
         id="section-lJXmKdOUFI" data-v-0bad29cf="">
      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-nC2zygXnm7t"
             id="row-nC2zygXnm7t" data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-oOMEZuxfJHu" className="c-column c-wrapper col-oOMEZuxfJHu" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="image-dqfucED-IpX" className="c-image c-wrapper image-dqfucED-IpX" data-v-0bad29cf="">
                  <div className="image-container cimage-dqfucED-IpX" data-v-5d0761c8="">
                    <div data-v-5d0761c8="">
                      <div>
                        <img src="./images/Qualifications-transformed.png" alt=""
                             className="img-round-corners img-border-none shadow4 img-effects-none" height="auto"
                             width="auto" loading="lazy"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="col-Q3QgRz1zBtC" className="c-column c-wrapper col-Q3QgRz1zBtC" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="heading-1TVQ-HB8N2d" className="c-heading c-wrapper" data-v-0bad29cf="">
                  <div className="heading-1TVQ-HB8N2d text-output cheading-1TVQ-HB8N2d none noBorder radius0 none">
                    <div>
                      <h2 className={"text-left"}>ERC Qualifications</h2>
                    </div>
                  </div>
                </div>
                <div id="divider-DQaKJbePl" className="text-left c-divider c-wrapper divider" data-v-0bad29cf="">
                  <div id="divider-DQaKJbePl" className="divider-element divider-element--left cdivider-DQaKJbePl"/>
                </div>
                <div id="sub-heading-tyglEYNSVbN" className="c-sub-heading c-wrapper" data-v-0bad29cf="">
                  <div
                    className="sub-heading-tyglEYNSVbN text-output csub-heading-tyglEYNSVbN none noBorder radius0 none">
                    <div>
                      <h2>
                        While the general qualifications for the ERC program seem
                        simple, the interpretation of each qualification is very
                        complex. Our significant experience allows us to ensure
                        we maximize any qualifications that may be available to your
                        company.
                      </h2>
                    </div>
                  </div>
                </div>

                <div id="paragraph-qsljJJ2Ub5g" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-qsljJJ2Ub5g text-output cparagraph-qsljJJ2Ub5g none noBorder radius0 none">
                    <div><p>Full or Partial Suspension of Business Operations</p></div>
                  </div>
                </div>

                <div id="paragraph-4rrhGqKdL9r" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-4rrhGqKdL9r text-output cparagraph-4rrhGqKdL9r none noBorder radius0 none">
                    <div>
                      <p>
                        A government authority required partial or full shutdown of
                        your business during 2020 or 2021. This includes your
                        operations being limited by commerce, inability to travel,
                        or restrictions of group meetings.
                      </p>
                    </div>
                  </div>
                </div>

                <div id="paragraph-0ZMvtj6cCtU" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-0ZMvtj6cCtU text-output cparagraph-0ZMvtj6cCtU none noBorder radius0 none">
                    <div><p>Gross Receipts Reduction</p></div>
                  </div>
                </div>

                <div id="paragraph-zsMzuTHLcm7" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-zsMzuTHLcm7 text-output cparagraph-zsMzuTHLcm7 none noBorder radius0 none">
                    <div><p>Gross receipt reduction criteria are different for 2020 and
                      2021 but are measured against the current quarter as compared to
                      2019 pre-COVID amounts.</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionQualifications;