import React, {Fragment} from "react";
import SectionIntro from "./SectionIntro";
import SectionEmployeeRetention from "./SectionEmployeeRetention";
import SectionCalculate from "./SectionCalculate";
import Header from "../../Common/Header";
import SectionWhy from "./SectionWhy";
import SectionReadyToStart from "./SectionReadyToStart";
import SectionQualifications from "./SectionQualifications";
import SectionFAQ from "./SectionFAQ";
import Footer from "../../Common/Footer";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <div id="preview-container" className="preview-container hl_page-preview--content">
        <SectionIntro />
        <SectionEmployeeRetention />
        <SectionCalculate />
        <SectionWhy />
        <SectionReadyToStart />
        <SectionQualifications />
        <SectionFAQ />
        <SectionReadyToStart />
        <Footer />
      </div>
    </Fragment>
  )
}
export default Home
