import React, {Fragment} from "react";
import Header from "../../Common/Header";

const ThankYou = () => {
  return (
    <Fragment>
      <Header/>
      <div id="preview-container" className="preview-container hl_page-preview--content section-apply">
        <div className="section-fullHeight container-wrapper text-center">
          <h3 className="mt-2">Thank you</h3>
          <p>
            Your submission has been received.
          </p>
          <p>
            We will be in touch and contact you soon.
          </p>
          <div className="mt-5">
            <a className="cbutton" href="/#">
              Back Home
            </a>
          </div>

        </div>

      </div>
    </Fragment>
  )
}
export default ThankYou