import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import {SITE_URL} from "../../constants";
import NotFound from "../Pages/NotFound/NotFoud";
import Apply from "../Pages/Apply/Apply";
import Home from "../Pages/Home/Home";
import ThankYou from "../Pages/ThankYou/ThankYou";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";

const AppRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path={SITE_URL + '/'} element={<Home/>}/>
        <Route path={SITE_URL + '/thank-you'} element={<ThankYou/>}/>
        <Route path={SITE_URL + '/apply'} element={<Apply/>}/>
        <Route path={SITE_URL + '/privacy-policy'} element={<PrivacyPolicy/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </HashRouter>
  )
}

export default AppRoutes;