const SectionEmployeeRetention = () => {
  return (
    <div className="section-employee fullSection none noBorder radius0 none c-section c-wrapper section-fHf4N40kSv"
         id="section-fHf4N40kSv" data-v-0bad29cf="">
      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-Xq_KNLUt1j" id="row-Xq_KNLUt1j"
             data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-E_d42d7l7" className="c-column c-wrapper col-E_d42d7l7" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="heading-w02S1NGlkYy" className="c-heading c-wrapper" data-v-0bad29cf="">
                  <div className="heading-w02S1NGlkYy text-output cheading-w02S1NGlkYy none noBorder radius0 none">
                    <div className="text-center">
                      <p className={"c-heading"}>The Employee Retention Credit</p>
                    </div>
                  </div>
                  
                </div>
               
                <div id="divider-CNWB_n9KQ" className="c-divider c-wrapper divider" data-v-0bad29cf="">
                  <div id="divider-CNWB_n9KQ" className="divider-element cdivider"/>
                  </div>
               
                <div id="paragraph-qaUoUWmQYg0" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-qaUoUWmQYg0 text-output cparagraph-qaUoUWmQYg0 none noBorder radius0 none">
                    <div><p>COVID-19 has been a challenge for all of us. As the world shut down the economy came to a
                      halt, leaving the future of many businesses in a precarious position. As we begin to find normalcy
                      again, businesses now have to focus on recovering and fortunately, several government programs are
                      easing this process.</p><p><br/></p><p>One such program is the Employee Retention Credit or ERC,
                      which is a generous stimulus program designed to bolster those businesses that were able to retain
                      their employees during this challenging time. Due to the extremely complex tax code and
                      qualifications, it is severely underutilized. Details of the program include:</p></div>
                  </div>
                  
                </div>
                </div>
              
            </div>
            </div>
          
        </div>
       
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-S7rM5GJno2B"
             id="row-S7rM5GJno2B" data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-jz-aDKuB8XH" className="c-column c-wrapper col-jz-aDKuB8XH" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="paragraph-oz1it1utYmq" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>Up to $33,000 per employee</strong></p></div>
                  </div>
                  
                </div>
               
                <div id="paragraph-OyRBgRbkmCI" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>Available for 2020 &amp; Q1 - Q3 2021</strong></p></div>
                  </div>
                  
                </div>

                <div id="paragraph--KlTXn02RYG" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>New startup business after 2/15/2020</strong></p></div>
                  </div>

                </div>

                <div id="paragraph--KlTXn02RYG" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>Qualify with decreased revenue or covid event</strong></p></div>
                  </div>
                  
                </div>
               
                <div id="paragraph-w_A5jasnNbF" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>No limit on funding (ERC is not a loan)</strong></p></div>
                  </div>
                  
                </div>
               
                <div id="paragraph-xlk_5Q9tTiG" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                  <div className="paragraph-oz1it1utYmq text-output cparagraph-oz1it1utYmq none noBorder radius0 none">
                    <div><p><strong>ERC is a <u>refundable</u> tax credit</strong></p></div>
                  </div>
                  
                </div>
               
                <div id="button-vmWu2OAcz" className="mt-5 c-button c-wrapper button-vmWu2OAcz" data-v-0bad29cf="">
                  <a
                  href="https://www.irs.gov/newsroom/new-law-extends-covid-tax-credit-for-employers-who-keep-workers-on-payroll"
                  target="_blank"
                  rel="noreferrer"
                  className="d-button cbutton-vmWu2OAcz none btnshadow custom btn-vp btn-hp borderFull radius50 none"
                  data-v-02382963="">
                    <span className="main-heading-group" data-v-02382963="">
                    <span className="button-icon-start" data-v-02382963=""></span>
                    <span className="main-heading-button" data-v-02382963="">see the irs tax code</span>
                    <span className="button-icon-end" data-v-02382963=""></span></span>
                  </a>
                </div>
               </div>
              
            </div>
           
            <div id="col-3FOEHvZc8au" className="c-column c-wrapper col-3FOEHvZc8au" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="image-CBfsUsxElyS" className="c-image c-wrapper image-CBfsUsxElyS" data-v-0bad29cf="">
                  <div className="cursor-default image-container cimage-CBfsUsxElyS" data-v-5d0761c8="">
                    <div data-v-5d0761c8="">
                      <div><img src="/images/check-women.jpg"
                                alt=""
                                className=" img-round-corners img-border-none shadow4 img-effects-none img-none img-border-none img-shadow-none img-effects-none"
                                height="auto" width="auto" loading="lazy"/></div>
                    </div>
                  </div>
                  
                </div>
                </div>
              
            </div>
            </div>
          
        </div>
        </div>
      
    </div>
  );
}

export default SectionEmployeeRetention;