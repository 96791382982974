import React from "react";

const NotFound = () => {
  return (
    <div className="page-notfound">
      Not Found
    </div>
  )
}
export default NotFound
