import React, {useState} from "react";
import { HashLink } from 'react-router-hash-link';
import {scrollWithOffset} from "../../helpers/common";

const Header = () => {
  const [isMobileNavVisible, setMobileNavVisible] = useState(false);
  const mobileNavClicked = () => {
    setMobileNavVisible(!isMobileNavVisible);
  }
  return (
    <header
      className="header fullSection shadow10 borderBottom radius0 none sticky-section c-section c-wrapper section-U_kXsU6Ny"
      id="section-U_kXsU6Ny"
    >

      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-AHiOTbKjvP" id="row-AHiOTbKjvP"
             data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-ZtuoJsqnD" className="c-column c-wrapper col-ZtuoJsqnD" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="nav-menu-6QZ2eehQT" className="c-nav-menu c-wrapper nav-menu-6QZ2eehQT" data-v-0bad29cf="">
                  <div className="default nav-menu-wrapper cnav-menu-6QZ2eehQT noBorder radius0 none">
                    <div className="mobile-nav">
                      <div className="branding">
                        <div className="logo">
                          <div className="image-container noBorder radius0 none"
                               data-v-5d0761c8="">
                            <div data-v-5d0761c8="">
                              <a href="/#" target="_self">
                                <img src="/images/ERC-logo512.jpg"
                                     alt="Brand Logo" className="noBorder radius0 none" height="auto" width="80"/>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-menu-mobile">
                        <span onClick={mobileNavClicked} className="menu-icon"/>
                      </div>
                    </div>
                    <div className= {"mobile-menu " + (isMobileNavVisible ? "mobile-menu-visible" : "")}>
                      <ul className="nav-menu">
                        <li className="nav-menu-item">
                          <HashLink smooth to="/#" scroll={el => scrollWithOffset(el)}>Home</HashLink>
                        </li>
                        <li className="nav-menu-item">
                          <HashLink smooth to="/#section-Or-d-DesIL" target="" scroll={el => scrollWithOffset(el)}>Why ERC Refunders?</HashLink>
                        </li>
                        <li className="nav-menu-item">
                          <HashLink smooth to="/#section-lJXmKdOUFI" target="" scroll={el => scrollWithOffset(el)}>ERC Qualifications</HashLink>
                        </li>
                        <li className="nav-menu-item">
                          <HashLink smooth to="/#section-X2M8ZlQCsa" target="" scroll={el => scrollWithOffset(el)}>FAQ</HashLink>
                        </li>
                      </ul>
                      <div id="col-i2Uk_ut6I" className="c-column c-wrapper col-i2Uk_ut6I desktop-only" data-v-0bad29cf="">
                        <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                          <div id="button-bQCdrgqx8" className="c-button c-wrapper button-bQCdrgqx8" data-v-0bad29cf="">
                            <a href="/#/apply" rel="noreferrer"
                               className="cbutton-bQCdrgqx8 none button-shadow-sharp1 custom btn-vp btn-hp radius100 none"
                               data-v-3e12c334="">
                                <span className="main-heading-group" data-v-3e12c334="">
                                    <span className="button-icon-start" data-v-3e12c334=""/><span
                                  className="main-heading-button" data-v-3e12c334="">Begin Qualifying</span><span
                                  className="button-icon-end" data-v-3e12c334=""/>
                                </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
