export const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -115;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

export const getTotalRefund = (ref2020: number, ref2021: number) => {
  const preQ = 5e3 * ref2020 + 21e3 * ref2021;
  const val = preQ
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  return (val === '0.00' ? '$X,XXX,XXX' : val);
}