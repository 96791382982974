const SectionFAQ = () => {
  return (
    <div className="fullSection none noBorder radius0 none c-section c-wrapper section-X2M8ZlQCsa section-faq"
         id="section-X2M8ZlQCsa" data-v-0bad29cf="">
      <div className="inner" data-v-0bad29cf="">
        <div className="row-align-center none noBorder radius0 none c-row c-wrapper row-kIBokP5AxVV" id="row-kIBokP5AxVV"
             data-v-0bad29cf="">
          <div className="inner" data-v-0bad29cf="">
            <div id="col-xWg768Ug8MV" className="c-column c-wrapper col-xWg768Ug8MV" data-v-0bad29cf="">
              <div className="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf="">
                <div id="heading-vUO7oDomdWZ" className="c-heading c-wrapper" data-v-0bad29cf="">
                  <div className="heading-vUO7oDomdWZ text-output cheading-vUO7oDomdWZ none noBorder radius0 none">
                    <div><p>Frequently Asked Questions</p></div>
                  </div>
                </div>
                <div id="divider-riYCVBQxh" className="c-divider c-wrapper divider-riYCVBQxh" data-v-0bad29cf="">
                  <div id="divider-riYCVBQxh" className="divider-element cdivider-riYCVBQxh"/>
                </div>

                <div className="questions">
                  <div id="paragraph-FsppuIolSu7" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-FsppuIolSu7 text-output cparagraph-FsppuIolSu7 none noBorder radius0 none">
                      <div>
                        <p><strong>Do I have to repay the ERC Credit?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-ZtO4g0hslKe" className="d-divider  c-wrapper divider-ZtO4g0hslKe" data-v-0bad29cf="">
                    <div id="divider-ZtO4g0hslKe" className="divider-element cdivider-ZtO4g0hslKe"/>
                  </div>

                  <div id="paragraph-xw9-0_vTSex" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-xw9-0_vTSex text-output cparagraph-xw9-0_vTSex none noBorder radius0 none">
                      <div>
                        <p>No. This is not a loan. It's a <u>refundable</u> tax credit.
                          When we file your ERC claim we request a refund check for
                          you.</p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-3tiMdj2geKd" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-3tiMdj2geKd text-output cparagraph-3tiMdj2geKd none noBorder radius0 none">
                      <div>
                        <p><strong>How much do you charge?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-FPNf1zbXiMh" className="d-divider  c-wrapper divider-FPNf1zbXiMh" data-v-0bad29cf="">
                    <div id="divider-FPNf1zbXiMh" className="divider-element cdivider-FPNf1zbXiMh"/>
                  </div>

                  <div id="paragraph-mAjNTTeYg7V" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-mAjNTTeYg7V text-output cparagraph-mAjNTTeYg7V none noBorder radius0 none">
                      <div>
                        <p>
                          Our service charge is based on a percentage of the credit
                          recovered. We calculate and provide our fee with our free
                          analysis. Since we are typically able to recover 10-20%
                          more than someone less familiar with the program, our fee is
                          very affordable. Just like a good CPA, using the right team
                          for this process pays for itself.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div id="paragraph-pQ6fQHkHavX" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-pQ6fQHkHavX text-output cparagraph-pQ6fQHkHavX none noBorder radius0 none">
                      <div>
                        <p><strong>Can't I just have my CPA file? Why would I use ERC
                          Refunders?</strong></p>
                      </div>
                    </div>
                  </div>

                  <div id="divider-38NcOsOKq8r" className="d-divider  c-wrapper divider-38NcOsOKq8r" data-v-0bad29cf="">
                    <div id="divider-38NcOsOKq8r" className="divider-element cdivider-38NcOsOKq8r"/>
                  </div>

                  <div id="paragraph-YvjcKyk-hXi" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-YvjcKyk-hXi text-output cparagraph-YvjcKyk-hXi none noBorder radius0 none">
                      <div>
                        <p>
                          Of course. The challenge is the ERC credit is taken on your
                          payroll returns and not through your business income tax
                          returns, which is what most CPA's handle. Because of
                          this most CPA's don't process this credit, unless they
                          process your payroll in house. This is also a big reason why
                          this credit is so underutilized. Since CPA's don't
                          typically handle it and they are the tax experts, it has
                          mostly fallen in a middle ground where few are able to
                          effectively process the credit. Interestingly, we receive a
                          large portion of our clients from CPA's.
                        </p>
                        <p><br/></p>
                        <p>
                          At ERC Refunders our professionals have decades of payroll experience,
                          which has allowed us to specifically focus to understanding
                          and maximizing the ERC program. In our experience we
                          have found that due to the complexity (the ERC tax code is
                          over 200 pages) and time investment necessary to understand
                          the ERC program, very few are able to effectively
                          maximize this sizeable credit for your business.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-05ohBpbWjsk" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-05ohBpbWjsk text-output cparagraph-05ohBpbWjsk none noBorder radius0 none">
                      <div>
                        <p><strong>Can I get ERC Funds if I already took the
                          PPP?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-Y3X6pw4DYme" className="d-divider  c-wrapper divider-Y3X6pw4DYme" data-v-0bad29cf="">
                    <div id="divider-Y3X6pw4DYme" className="divider-element cdivider-Y3X6pw4DYme"/>
                  </div>

                  <div id="paragraph-QOL-mSz4P2W" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-QOL-mSz4P2W text-output cparagraph-QOL-mSz4P2W none noBorder radius0 none">
                      <div>
                        <p>
                          Yes. The Taxpayer Certainty and Disaster Tax Relief Act of
                          2020, enacted December 27, 2020, modified the ERC credit
                          rules. One of the modifications included allowing a
                          company to have a PPP loan and still take advantage of the
                          ERC credit. However, you can't use the same dollar for
                          dollar funds. We take this into account when processing
                          your ERC credit.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div id="paragraph-ymzAf61AfWs" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-ymzAf61AfWs text-output cparagraph-ymzAf61AfWs none noBorder radius0 none">
                      <div>
                        <p><strong>My revenue went up in 2020, can I still qualify for
                          the ERC program?</strong></p>
                      </div>
                    </div>
                  </div>

                  <div id="divider-zdieKJqJk4g" className="d-divider  c-wrapper divider-zdieKJqJk4g" data-v-0bad29cf="">
                    <div id="divider-zdieKJqJk4g" className="divider-element cdivider-zdieKJqJk4g"/>
                  </div>

                  <div id="paragraph-WTsuMb4hQDz" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-WTsuMb4hQDz text-output cparagraph-WTsuMb4hQDz none noBorder radius0 none">
                      <div>
                        <p>
                          Yes! There are two possible qualifications for 2020: revenue
                          reduction, or a "full or partial shutdown of your business
                          due to COVID-19". Specifically the IRS describes
                          this as "A government authority required partial or full
                          shutdown of your business during 2020 or 2021. This includes
                          your operations being limited by commerce, inability
                          to travel or restrictions of group meetings." Below are
                          several examples of qualifying events:
                        </p>
                        <p><br/></p>
                        <p>Example 1: A restaurant must close or limit its on-site
                          dining. Such as having to close down every other table, due
                          to COVID-19 restrictions.</p>
                        <p><br/></p>
                        <p>Example 2: A business that needs to meet with clients in
                          person and has to cancel meetings due to COVID-19
                          restrictions.</p>
                        <p><br/></p>
                        <p>Example 3: A business has to reduce their operating hours
                          because COVID-19 restrictions and cleaning requirements.</p>
                        <p><br/></p>
                        <p>Example 4: A business had delayed production timelines caused
                          by supply chain disruptions.</p>
                        <p><br/></p>
                        <p>Example 5: A business with a planned event has to cancel that
                          event, or restrict the amount of people who can attend due
                          to COVID-19 restrictions.</p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-SP8Iozf699_" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-SP8Iozf699_ text-output cparagraph-SP8Iozf699_ none noBorder radius0 none">
                      <div>
                        <p><strong>I'm a CPA or a payroll company, can I refer my
                          clients to ERC Refunders?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-H9CQ5Sl7Mjp" className="d-divider  c-wrapper divider-H9CQ5Sl7Mjp" data-v-0bad29cf="">

                    <div id="divider-H9CQ5Sl7Mjp" className="divider-element cdivider-H9CQ5Sl7Mjp"></div>

                  </div>

                  <div id="paragraph-0Uhs8t8mHad" className="c-paragraph c-wrapper" data-v-0bad29cf="">
                    <div className="paragraph-GKv08TozmWd text-output cparagraph-GKv08TozmWd none noBorder radius0 none">
                      <div><p>Yes, you certainly can. We would love to work with you. Please email us at info@ercfunders.com</p></div>
                    </div>
                  </div>

                  <div id="paragraph-9OPHo9S7t2g" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-9OPHo9S7t2g text-output cparagraph-9OPHo9S7t2g none noBorder radius0 none">
                      <div>
                        <p><strong>Will the ERC funds run out?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-ZJeZOtoyh4C" className="d-divider  c-wrapper divider-ZJeZOtoyh4C" data-v-0bad29cf="">

                    <div id="divider-ZJeZOtoyh4C" className="divider-element cdivider-ZJeZOtoyh4C"></div>

                  </div>

                  <div id="paragraph-GKv08TozmWd" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-GKv08TozmWd text-output cparagraph-GKv08TozmWd none noBorder radius0 none">
                      <div><p>This is not a lending program - tax refunds are issued by
                        the US Treasury. Therefore, all eligible employers will receive
                        the funds.</p></div>
                    </div>

                  </div>

                  <div id="paragraph-1vIAW4Aar0B" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-1vIAW4Aar0B text-output cparagraph-1vIAW4Aar0B none noBorder radius0 none">
                      <div>
                        <p><strong>How will I make payment?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-XGYZb9UtmaT" className="d-divider  c-wrapper divider-XGYZb9UtmaT" data-v-0bad29cf="">

                    <div id="divider-XGYZb9UtmaT" className="divider-element cdivider-XGYZb9UtmaT"></div>

                  </div>

                  <div id="paragraph-UPPp_W33BF2" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-UPPp_W33BF2 text-output cparagraph-UPPp_W33BF2 none noBorder radius0 none">
                      <div>
                        <p>
                          No fee is charged to you unless we get you approved.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-lCHpXXqw-iG" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-lCHpXXqw-iG text-output cparagraph-lCHpXXqw-iG none noBorder radius0 none">
                      <div>
                        <p><strong>I'm getting more in ERC Credit than I paid in
                          taxes?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-ZAvyLw-lnwd" className="d-divider  c-wrapper divider-ZAvyLw-lnwd" data-v-0bad29cf="">

                    <div id="divider-ZAvyLw-lnwd" className="divider-element cdivider-ZAvyLw-lnwd"></div>

                  </div>

                  <div id="paragraph-tIlOFW34cYB" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-tIlOFW34cYB text-output cparagraph-tIlOFW34cYB none noBorder radius0 none">
                      <div>
                        <p>
                          Remember that this program is taken according to payroll
                          taxes paid, not on income taxes. ERC funds not applied
                          towards owed payroll taxes are treated as an 'overdeposit'
                          of taxes that will be requested as a refund check from the
                          IRS.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-UNzFS8r0_S9" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-UNzFS8r0_S9 text-output cparagraph-UNzFS8r0_S9 none noBorder radius0 none">
                      <div>
                        <p><strong>As an owner do my wages, or the wages of any family
                          member I employ qualify?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-wFq44Uqd1qs" className="d-divider  c-wrapper divider-wFq44Uqd1qs" data-v-0bad29cf="">

                    <div id="divider-wFq44Uqd1qs" className="divider-element cdivider-wFq44Uqd1qs"></div>

                  </div>

                  <div id="paragraph-IUbbWPnYsO6" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-IUbbWPnYsO6 text-output cparagraph-IUbbWPnYsO6 none noBorder radius0 none">
                      <div>
                        <p>
                          Maybe. Wages of owners who have majority ownership, defined
                          as over 50%, do not qualify, nor do the W2 wages of any
                          immediate family members of the owner. In the case an
                          owner has less than 50% ownership, their W2 wages qualify,
                          as do the W2 wages paid to immediate family members.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph--0B60c91-oG" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph--0B60c91-oG text-output cparagraph--0B60c91-oG none noBorder radius0 none">
                      <div>
                        <p><strong>Can I qualify for the ERC if I'm self
                          employed?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-6qjSrFPzpNN" className="d-divider  c-wrapper divider-6qjSrFPzpNN" data-v-0bad29cf="">

                    <div id="divider-6qjSrFPzpNN" className="divider-element cdivider-6qjSrFPzpNN"></div>

                  </div>

                  <div id="paragraph-K5BY_dHmLqa" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-K5BY_dHmLqa text-output cparagraph-K5BY_dHmLqa none noBorder radius0 none">
                      <div><p>No, if you are majority owner (over 50%) of your company
                        then your wages do not qualify.</p></div>
                    </div>

                  </div>

                  <div id="paragraph-zlre2ogCdY_" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-zlre2ogCdY_ text-output cparagraph-zlre2ogCdY_ none noBorder radius0 none">
                      <div>
                        <p><strong>How long does it take to get my ERC Credit?</strong>
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-buoimAPtwzD" className="d-divider  c-wrapper divider-buoimAPtwzD" data-v-0bad29cf="">

                    <div id="divider-buoimAPtwzD" className="divider-element cdivider-buoimAPtwzD"></div>

                  </div>

                  <div id="paragraph-hqelgBZermw" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-hqelgBZermw text-output cparagraph-hqelgBZermw none noBorder radius0 none">
                      <div>
                        <p>
                          This is how the process works: you send us the required
                          documents and we process an analysis at no charge. The
                          process from start to finish usually happens in 2-3 weeks.
                          If
                          you wish to move forward, you will select your desired
                          payment option and we file your claim. Once filed, refunds
                          are released based on IRS backlog. Currently, the IRS has
                          stipulated a 20 week minimum turnaround on the ERC refunds.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="paragraph-SbYcXPTSAqH" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-SbYcXPTSAqH text-output cparagraph-SbYcXPTSAqH none noBorder radius0 none">
                      <div>
                        <p><strong>Is the ERC Credit taxable?</strong></p>
                      </div>
                    </div>

                  </div>

                  <div id="divider-3NKprx7rBqs" className="d-divider  c-wrapper divider-3NKprx7rBqs" data-v-0bad29cf="">

                    <div id="divider-3NKprx7rBqs" className="divider-element cdivider-3NKprx7rBqs"></div>

                  </div>

                  <div id="paragraph-gjevhO_RA_Q" className="c-paragraph c-wrapper" data-v-0bad29cf="">

                    <div className="paragraph-gjevhO_RA_Q text-output cparagraph-gjevhO_RA_Q none noBorder radius0 none">
                      <div>
                        <p>
                          The ERC credit is not considered income for federal income
                          tax purposes, but you must reduce any deductible wage
                          expenses by the amount of the credit. Please provide the
                          credit information to your CPA for tax purposes.
                        </p>
                      </div>
                    </div>

                  </div>

                  <div id="sub-heading-93_gKe3g-b6" className="c-sub-heading c-wrapper" data-v-0bad29cf="">

                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}
  
export default SectionFAQ